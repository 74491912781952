import React from 'react';

export default function Container({ children, padding = '0px' }) {
  return (
    <div
      className="bg-primary"
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `linear-gradient(rgba(52, 152, 219, 0.5), rgba(52, 152, 219, 0.5)), url('/images/Logo_AAPCMR.jpg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        padding: padding
      }}
    >
      <div className="background-animation" />
      <div style={{ width: '100%', maxWidth: '900px' }}>{children}</div>
    </div>
  );
}
